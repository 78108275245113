<template>
    <div id="properties">
        <div class="header">
            <PageTitle :title="title" />
            <div v-if="$store.getters.loaded" id="filter" >
                <p class="select_label">Show me:</p>
                <div id="filter_options">
                    <button @click="changeFilter" class="filter_button" :class="{active: filter === 'all'}">All</button>
                    <button @click="changeFilter" class="filter_button" :class="{active: filter === 'available'}">Available</button>
                    <button @click="changeFilter" class="filter_button" :class="{active: filter === 'sold'}">Sold</button>
                </div>
            </div>
        </div>
        <div class="container">
            <div v-if="!$store.getters.loaded">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <div v-else id="propertyListing">
                <PropertyListing 
                    v-for="item in filteredProperties"
                    :id="item.PropertyData.Id"
                    :key="item.PropertyData.Id"
                    :slug="item.PropertyData.Slug"
                    :tag="item.PropertyData.Special" 
                    :imgURL="item.PropertyData.DisplayImage ? url+ item.PropertyData.DisplayImage.url : placeholderImage"
                    :address="item.PropertyData.Address"
                    :price="item.PropertyData.Price"
                    :lease="item.PropertyData.Lease">
                </PropertyListing>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import Spinner from 'vue-simple-spinner'

import lazyLoadComponent from '../utils/lazy-load-component';
import SkeletonBox from '../utils/Skeleton';

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

export default {
    name: "Properties",
    components: {
        PageTitle,
        Spinner,
        PropertyListing: lazyLoadComponent({
            ...defaultOptions,
            componentFactory: () => import(`../components/PropertyListing.vue`),
        }),
    },
    data(){
        return{
            title: "Properties",
            filter: "all",
            placeholderImage: "",
            filteredData: [],
            url: 'https://api.austinrobbins.com'
        }
    },
    metaInfo: {
        title: 'Properties | Bob Safranski',
        meta: [{
            name: 'description', 
            content: "Mindful real estate"
        }]
    },
    methods:{
        changeFilter(e){
            this.filter = e.target.textContent.toLowerCase();
        }
    },
    computed:{
        filteredProperties:function(){
            const myProperties = this.$store.getters.properties;
            let filteredProperties = []
            if(this.filter === 'all'){
                filteredProperties = myProperties;
            } else if (this.filter === 'available'){
                filteredProperties = myProperties.filter((item) =>{
                    return item.PropertyData.Special === 'newListing' || item.PropertyData.Special === 'offMarket' || item.PropertyData.Special === 'none';
                });
            } else{
                filteredProperties = myProperties.filter((item) =>{
                    return item.PropertyData.Special === 'sold' || item.PropertyData.Special === 'inEscrow' || item.PropertyData.Special === 'leased';
                });
            }
            return filteredProperties;
        },
    }
}
</script>

<style lang="scss" scoped>
#propertyListing{
    display: flex;
    flex-wrap: wrap;
    .property:nth-child(odd){
        padding: 0 0.75rem 3.75rem 1.5rem;

    }
    .property:nth-child(even){
        padding: 0 1.5rem 3.75rem 0.75rem;
    }
}
.container{
    min-height: 50vh;
}
.header{
    position: relative;
}
#filter{
    position: absolute;
    text-align: right;
    top: 18px;
    right: 20px;
    #filter_options{
        display: inline;
        .filter_button{
            font-size: 1rem;
            background: #fff;
            padding: 0.5rem 1rem;
            margin:0 0.4rem;
            border: 1px solid #000;
            opacity: 0.85;
            color: #000;
            &.active{
                background: #000;
                color: #fff;
            }
            &:hover{
                cursor: pointer;
                opacity: 1;
            }
        }
    }
}
.select_label{
    display: inline;
    margin-right: 0.4rem;
}
.header{
    max-width: 100%;
}

@media (max-width: 900px) { 
    #filter{
        position: unset;
        text-align: center;
        margin: unset;
        top: unset;
        padding: 1rem 0 2rem 0;
    }
    .container{
        margin-top: 70px;
    }
}


@media (max-width: $mobile) {
    #propertyListing{
        display:block;
    }
    #propertyListing{
        .property:nth-child(odd){
            padding: 0 0 3.75rem 0;
        }
        .property:nth-child(even){
            padding: 0 0 3.75rem 0;
        }
    }
}

</style>
